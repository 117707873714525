var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dot"},[_vm._m(0),_c('div',{staticClass:"container-fluid section contact",attrs:{"id":"contact"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"col-md-12 col-md-offset-0 col-lg-8 col-lg-offset-1 col-xl-9 col-xl-offset-1 content"},[_vm._m(3),_c('div',{staticClass:"row form"},[_c('div',{staticClass:"col-12 inner"},[_c('form',{attrs:{"method":"post","action":"contact_form.php"},on:{"submit":_vm.checkForm}},[_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userAgent),expression:"userAgent"}],staticClass:"form-control",attrs:{"name":"useragent","hidden":""},domProps:{"value":(_vm.userAgent)},on:{"input":function($event){if($event.target.composing)return;_vm.userAgent=$event.target.value}}})]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn-main",attrs:{"name":"submit","type":"submit"}},[_c('i',{staticClass:"fas fa-share"}),_vm._v(" "+_vm._s(_vm.msgBtn))])])])])])])]),_c('div',{staticClass:"row social"},[_c('ul',_vm._l((_vm.socials),function(social,key){return _c('li',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(social.name),expression:"social.name",modifiers:{"bottom":true}}],key:key},[_c('a',{class:social.name,attrs:{"href":social.link,"target":"_blank"}},[_c('i',{class:'fab fa-' + social.icon})])])}),0)])])])])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"form-submit","tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered modal-lg",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('i',{staticClass:"fal fa-times"})])]),_c('div',{staticClass:"modal-body text-center"},[_c('h2',{staticClass:"mb-4"},[_vm._v("Thank you!")]),_c('p',[_vm._v("Your message has been submitted successfully and I'll get back to you shortly.")])]),_c('div',{staticClass:"modal-footer"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 title"},[_c('div',{staticClass:"inner white"},[_c('i',{staticClass:"fal fa-envelope"}),_vm._v(" Contact Me ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3 col-xl-3 side"},[_c('div',{staticClass:"qrcode hvr-wobble-vertical"},[_c('img',{attrs:{"src":require("../assets/img/qr-code.png"),"alt":"QR Code"}}),_c('h6',[_vm._v("Get my contact details on your phone by scanning the QR-code.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text"},[_c('div',{staticClass:"col-md-12 inner"},[_c('p',[_vm._v(" I can be reached at "),_c('a',{attrs:{"href":"mailto:eslami.roozbeh@gmail.com","target":"_blank"}},[_vm._v("eslami.roozbeh [at] gmail [dot] com")]),_vm._v(" or you can send me a message using the form below and I will get back to you as soon as possible. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('input',{staticClass:"form-control",attrs:{"name":"name","placeholder":"name","type":"text","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('input',{staticClass:"form-control",attrs:{"name":"email","placeholder":"email","type":"email","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('textarea',{staticClass:"form-control",attrs:{"name":"message","rows":"20","cols":"20","placeholder":"message","required":""}})])
}]

export { render, staticRenderFns }